import React, { useState, useEffect } from 'react';
import {  Box, Grid, TextField,  } from '@mui/material';
import { Save } from '@mui/icons-material';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import PageHeading from "../page_heading";
import BackButton from "../common/back_button";
import CustomButton2 from "../common/button_two";
import ModelPopup from "../common/modal_popup"
import LoadingOverlay from '../common/loading_overlay';
import MessageSnackbar from '../common/message_snackbar';
import { basic, textFieldAndShrinkStyle } from '../../../themes/basic';

const AddProduct = () => {
  const [productName, setProductName] = useState(null);
  const [productDescription, setProductDescription] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [successMsg, setSuccessMsg] = useState(null);
  const [messageStatus, setMessageStatus] = useState(null);
  const [productExists, setProductExists] = useState(false); 
  const [checkMessage, setCheckMessage] = useState(''); 

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarVariant, setSnackbarVariant] = useState("success");

  const navigate = useNavigate() 

  const handleOpen = () => setModalOpen(true);
  const handleClose = () => setModalOpen(false);

  const checkProductExists = async (name) => {
    try {
      const productResponse = await axios.post("/product/checkproductname", { productName: name });
      if (productResponse.data.exists){
        setProductExists(true);
        setCheckMessage("This product name is already in use.");
      }else{
        setProductExists(false);
        setCheckMessage("Product name is available.");
      }
    } catch (error) {
      console.error("Error checking product name:", error);
    }
  };

  const handleProductNameChange = (e) => {
    const name = e.target.value;

    console.log("handleProductNameChange",name);
    setProductName(name);
    if (name.length > 0) {
      checkProductExists(name);  // Call the API to check product name
    } else {
      setProductExists(false);
      setCheckMessage('');
    }
  };

  const addProductFunc = async (e) => {
    setModalOpen(false);
    e.preventDefault();
    setLoading(true);

    let productData = {
      productName: productName,
      productDescription: productDescription,
    };
    
    const addProductData = await axios.post("/product/add", productData);
    if(addProductData.data.status=="success"){
      setProductName("");
      setProductDescription("");    
      setLoading(false);
      triggerSnackbar("The products has been successfully added.","success"); 
      //setMessageStatus("success");
      //setSuccessMsg("The products has been successfully added.");
    }else{
      setLoading(false);
      triggerSnackbar("Failed to add product.","error"); 
      //setMessageStatus("failed");
      //setSuccessMsg("Failed to add product.");
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const triggerSnackbar = (message, variant) => {
    setSnackbarMessage(message);
    setSnackbarVariant(variant);
    setSnackbarOpen(true);
  };

  useEffect(() => {
    // Fetch products from API
  }, []);

  return (
    <div style={{ marginTop: "0",position:"relative" }}> 
    
      <PageHeading title="Add Product" style={{marginBottom:"20px"}} />
      <Box
        sx={{
          height: "auto",
          width: "60%",
          margin:"auto auto auto auto",
          marginTop:"0px",
          ul: {
            bgcolor: "#f6f6f6",
            color: "#ffffff",
          },
        }}
       >
        <Grid container spacing={2} style={{ marginBottom: 20 }}>
            <Grid item sm={12}>
              <TextField
                id="product"
                label="Product Name"
                variant="outlined"
                size="small"
                required
                type="text"
                style={{ width: "100%",  fontFamily:basic.fontFamily }}
                sx={textFieldAndShrinkStyle}
                value={productName}
                onChange={handleProductNameChange}
                //onChange={(e) => setProductName(e.target.value )}
                error={productName === ""}
                helperText={productName === "" ? "Product name is required." : ""}
              />

            {productName && <div style={{ fontSize:basic.contentFontSize, color: productExists ? 'red' : 'green' }}>{checkMessage}</div>}

              <TextField
                id="description"
                label="Product Description"
                value={productDescription}
                variant="outlined"
                size="small"
                required
                type="text"
                multiline
                rows={4}
                style={{ width: "100%", marginTop:"20px", marginBottom:"20px",  fontFamily:basic.fontFamily }}
                sx={textFieldAndShrinkStyle}
                onChange={(e) => setProductDescription(e.target.value)}
                error={productDescription === ""}
                helperText={
                  productDescription === ""
                    ? "Product description is required."
                    : ""
                }
              />
              <div style={{ margin: 10 }} />
          

              <Grid container spacing={2} style={{ marginBottom: 20, marginLeft:"0px" }}>
                <CustomButton2
                  onClick={handleOpen}
                  disabled={
                  !(productName && productName.length > 0 && productDescription && productDescription.length > 0) ||
                  productExists // Disable button if product exists
                  }
                  aria-label="Save"
                  >
                  <Save fontSize={basic.headingFontSize} style={{ marginTop:-1 }} />
                  <div style={{ marginLeft: 5 }}>Save</div>
                </CustomButton2>
                <BackButton backToPage="/adminDashboard/product" aButtonText="Cancel" />
          
              </Grid>
          
            </Grid>
            <Grid item sm={4}>
            </Grid>
        </Grid>
      </Box>

      <ModelPopup
        open={modalOpen}
        onClose={handleClose}
        onConfirm={addProductFunc}
        title="Alert"
        description="Are you sure you want to add this product?"
        loading={loading}
        successMsg={successMsg}
        successStatus={messageStatus}
        confirmText="Confirm"
        cancelText="Cancel"
        onGoToNext={() => { navigate("/adminDashboard/product") }}
        onFinalButtonText="Go to Products"
      />

      <LoadingOverlay loading={loading} />

      <MessageSnackbar
        message={snackbarMessage}
        variant={snackbarVariant}
        open={snackbarOpen}
        onClose={handleSnackbarClose}
      />
    </div>
  );
};

export default AddProduct;
